<!--  -->
<template>
  <div class='payChart'>
    <el-card :body-style="{ padding: '10px'}">
      <!-- <el-tabs v-model="year">
        <el-tab-pane label="2021" :name="2021"></el-tab-pane>
        <el-tab-pane label="2022" :name="2022"></el-tab-pane>
      </el-tabs> -->
      <el-radio-group v-model="year" style="margin-bottom: 20px;">
        <el-radio-button v-for="(v, k) in times" :key="k" :label="k" :name="k">{{k}}年合同</el-radio-button>
      </el-radio-group>
      <div style="margin-bottom: 20px">
        <div class="shaixuan">
          <!-- <div>
            <el-date-picker
              @change="filterData"
              size="medium"
              v-model="times"
              type="monthrange"
              range-separator="至"
              start-placeholder="开始日期"
              value-format="yyyy-MM"
              end-placeholder="结束日期">
            </el-date-picker>
            <el-button type="primary" size="medium" style="margin-left: 10px" @click="getData">搜索</el-button>
          </div> -->
          <div>
            <span>投保总金额: {{total_insured_yfx}}</span>
            <span>理赔总金额: {{total_claim_price}}</span>
            <span>实际理赔金额: {{totalPrice}}</span>
          </div>
        </div>
      </div>
      <div class="chartParent">
        <div id="container" style="height: 100%"></div>
      </div>
    </el-card>
  </div>
</template>
<script>
import { getMonth, getYear } from "@/utils/utils";
var dom
export default {
  components: {},
  data() {
    return {
      year: '2022',
      times: {
        '2021': {start_month: '2021-02', end_month: '2022-03'}, 
        '2022': {start_month: '2022-06', end_month: '2022-12'},
      },
      month: [],
      insured_yfx: [],
      claims_price: [],
      claims_ratio: [],
      total_insured_yfx: 0,   // 投保总金额
      total_claim_price: 0,   // 理赔总金额
      average_claims: 0,

      totalPrice: 0,     // 总金额
      piccDetails: [],   // 嗨团实际理赔
      shijilipeilv: [],  // 上报理赔率
    }
  },
  computed: {},
  watch: {
    year() {
      this.getData()
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.setTime()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getData()
  },
  methods: {
    setTime() {
      let year = getYear()
      this.year = year
      for (let i = 2023; i <= year; i++) {
        console.log(i, year, '=====');
        if(i < year) {
          this.times[i] = {start_month: i+'-01', end_month: i+'-12'}
        } else {
          this.times[i] = {start_month: i+'-01', end_month: getMonth()}
        }   
      }
      console.log(this.times);
    },
    getData() {
      dom = document.getElementById("container");
      const loading = this.$loading({
        target: dom,
        text: '拼命加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      console.log(this.times[this.year], this.year);
      this.$http.get(`https://mng.htchaxun.cn/insured_order_data_month?start_month=${this.times[this.year]?.start_month}&end_month=${this.times[this.year]?.end_month}`).then(res => {
        this.month = res.data.month
        this.insured_yfx = res.data.insured_yfx.map(o => {
          return o.toFixed(2)
        })
        this.claims_price = res.data.claims_price.map(o => {
          return o.toFixed(2)
        })
        this.claims_ratio = res.data.claims_ratio
        this.total_insured_yfx = res.data.total_insured_yfx.toFixed(2)
        this.total_claim_price = res.data.total_claim_price.toFixed(2)
        this.average_claims = (res.data.total_claim_price/res.data.total_insured_yfx) * 100
        fetch(`http://yfxt.haituanvip.com/api/Customer/QueryPiccInfos?StartTime=${this.times[this.year]?.start_month}&EndTime=${this.times[this.year]?.end_month}`).then(function(response) {
          return response.text()
        }).then((body) => {
          body = JSON.parse(body)
          // console.log('body', body.data);
          this.totalPrice = body.data.totalPrice
          this.piccDetails = body.data.piccDetails.prices.map(o => {
            return o.toFixed(2)
          })
          let shijilipeilv = []
          for (let i = 0; i < this.insured_yfx.length; i++) {
            let toubao = 0 
            let lipei = 0
            for (let j = 0; j <= i; j++) {
              toubao += Number(this.insured_yfx[j])
              lipei += Number(this.piccDetails[j])
            }
            shijilipeilv[i] = (lipei / toubao * 100).toFixed(2)
          }
          this.shijilipeilv = shijilipeilv
          this.initEcharts()
          loading.close()
        })
      })
    },
    initEcharts() {
      var myChart = this.$echarts.init(dom);
      var option;
      option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'none',
          },
          formatter: '{b}<br>{a0}: {c0} 元<br>{a1}: {c1} 元<br>{a2}: {c2} 元<br>{a3}: {c3} %<br>{a4}: {c4} %'

        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        legend: {
          data: ['投保金额', '理赔金额', '实际理赔', '嗨团理赔率', '上报理赔率']
        },
        xAxis: [
          {
            type: 'category',
            data: this.month,
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: ' 金额',
            min: 0,
            max: 1000000,
            interval: 100000,
            axisLabel: {
              formatter: '{value}元'
            }
          },
          {
            type: 'value',
            name: '理赔率',
            min: 0,
            max: 200,
            interval: 20,
            axisLabel: {
              formatter: '{value}%'
            }
          }
        ],
        series: [
          {
            name: '投保金额',
            label: {
              show: true,
              position: 'top',
              // formatter: '{b}投保\n{c}'
              formatter: (o) => {
                let name = o.name.substr(o.name.length - 2, 2)
                let n = Number(name)
                return `${n}月\n投保\n${o.data}`
              }
            },
            itemStyle : { 
              color:'#006400',
            },
            type: 'bar',
            data: this.insured_yfx
          },
          {
            name: '理赔金额',
            label: {
              show: true,
              position: 'top',
              // formatter: '{b}理赔\n{c}'
              formatter: (o) => {
                let name = o.name.substr(o.name.length - 2, 2)
                let n = Number(name)
                return `${n}月\n理赔\n${o.data}`
              }
            },
            itemStyle : { 
              color:'#D2691E',
            },
            type: 'bar',
            data: this.claims_price
          },
          {
            name: '实际理赔',
            label: {
              show: true,
              position: 'top',
              formatter: (o) => {
                let name = o.name.substr(o.name.length - 2, 2)
                let n = Number(name)
                return `${n}月\n实际理赔\n${o.data}`
              }
            },
            itemStyle : { 
              color:'#4F77AC',
            },
            type: 'bar',
            data: this.piccDetails
          },


          {
            name: '嗨团理赔率',
            label: {
              show: true,
              position: 'top',
              formatter: (o) => {
                let name = o.name.substr(o.name.length - 2, 2)
                let n = Number(name)
                return `${n}月\n嗨团理赔率\n${o.data}%`
              }
            },
            type: 'line',
            yAxisIndex: 1,
            data: this.claims_ratio,
            itemStyle : { 
              normal : { 
                color:'#87CEFA', //改变折线点的颜色
                lineStyle:{ 
                  color:'#D2691E', //改变折线颜色
                  width: 4
                } 
              } 
            },
            markLine: {
              label: {
                formatter: '{c}%'
              },
              data: [
                {
                  name: '平均理赔率',
                  yAxis: this.average_claims
                }
              ]
            },
          },
          

          {
            name: '上报理赔率',
            label: {
              show: true,
              position: 'top',
              formatter: (o) => {
                let name = o.name.substr(o.name.length - 2, 2)
                let n = Number(name)
                return `${n}月\n上报理赔率\n${o.data}%`
              }
            },
            type: 'line',
            yAxisIndex: 1,
            data: this.shijilipeilv,
            itemStyle : { 
              normal : { 
                color:'red', //改变折线点的颜色
                lineStyle:{ 
                  color:'#483D8B', //改变折线颜色
                  width: 4
                } 
              } 
            },
            markLine: {
              label: {
                formatter: '{c}%'
              },
              // data: [
              //   {
              //     name: '平均理赔率',
              //     yAxis: this.average_claims
              //   }
              // ]
            },
          }
        ]
      };

      if (option && typeof option === 'object') {
          myChart.setOption(option);
      }
    }
  },
}
</script>
<style lang='scss' scoped>
.shaixuan {
  /* margin: 0 20px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  span {
    margin-left: 20px;
  }
}
.chartParent {
  height: 750px;
  
}
</style>