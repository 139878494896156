
  function doHandleZero(zero) {
    var date = zero;
    if (zero.toString().length == 1) {
        date = "0" + zero;
    }
    return date;
  }
  
  // 获取当月
  export function getMonth() {
    var myDate = new Date();
    var tYear = myDate.getFullYear()
    var tMonth = myDate.getMonth()
    tMonth = doHandleZero(tMonth + 1)

    return tYear + "-" + tMonth
}
  // 获取每个月一号
  export function getDateOne() {
      var myDate = new Date();
      var tYear = myDate.getFullYear()
      var tMonth = myDate.getMonth()
      tMonth = doHandleZero(tMonth + 1)

      return tYear + "-" + tMonth + "-01"
  }
  // 获取当前年-月-日
  export function getDateNow() {
    var myDate = new Date();
    var tYear = myDate.getFullYear()
    var tMonth = myDate.getMonth()
    var tDay = myDate.getDate()
    tMonth = doHandleZero(tMonth + 1)
    tDay = doHandleZero(tDay)

    return tYear + "-" + tMonth + "-" + tDay
  }
  // 获取当前第一天
  export function getFirthDay() {
    var myDate = new Date();
    var tYear = myDate.getFullYear()

    return tYear + "-01-01"
  }
  // 获取当前年
  export function getYear() {
    var myDate = new Date();
    var tYear = myDate.getFullYear()

    return tYear
  }

  // 上月最后一天
  export function getPreMonthDay() {
    var nowdays = new Date();
    var year = nowdays.getFullYear();
    var month = nowdays.getMonth();
    console.log(nowdays, month);
    if(month==0){
        month = 12;
        year = year-1;

    }
    if(month<10){
        month = '0'+month;
    }
    var myDate = new Date(year,month,0);

    return year+'-'+month+'-'+myDate.getDate();//上个月最后一天
  }
